.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.faq__title {
  font-size: 48px;
  margin-bottom: 30px;
  padding: 0;
}

.faq__container {
  width: 70%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 10px 0 20px;
  border-radius: 6px;
  cursor: pointer;
  background: rgb(0, 140, 0);
  margin-bottom: 10px;
  color: white;
}

.header__active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 10px 0 20px;
  border-radius: 6px;
  cursor: pointer;
  background: rgb(0, 100, 0);
  margin-bottom: 10px;
  color: white;
}

.header__active .faqItem__span {
  rotate: 180deg;
}

.faqItem__title {
  margin: 0;
  font-size: 14pt;
  font-weight: bold;
  color: white;
}

.faqItem__span {
  width: 30px;
  transition: 0.3s;
}

.faqItem__text {
  font-size: 18px;
  line-height: 1.7;
  padding: 0 20px 10px;
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease;
}

.collapse .show {
  height: auto;
}

@media (max-width:765px) {
  .faq {
    margin-bottom: 50px;
  }
  
  .faq__title {
    font-size: 32px;
    text-align: center;
  }
  
  .faq__container {
    width: 90%;
  }

  .header {
    height: auto;
    padding: 10px;
  }

  .header__active {
    height: auto;
    padding: 10px;
  }

  .faqItem__title {
    font-size: 16px;
    font-weight: 400;
  }

  .faqItem__text {
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px 10px;
  }
}