.quote {
  position: relative;
  background: url('../../images/green_map.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 200px 0 100px;
}

.quote h1 {
  font-size: 38px;
  color: white;
  line-height: 2rem;
  margin-bottom: 50px;
}

.quote__img {
  position: absolute;
  bottom: 0;
  left: 50px;
}

.quote__container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 100px 0;
}

.quote__button {
  padding: 25px;
  border-radius: 8px;
  border: none;
  color: white;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  background: rgb(0, 138, 0);
  transition: ease-in-out 0.2s;
  box-shadow: 5px 10px 5px 10px rgba(0,0,0,0.1);
  -webkit-box-shadow: 5px 10px 10px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 5px 10px 10px 1px rgba(0,0,0,0.1);
}

.quote__button:hover {
  background: rgb(0, 151, 0);
}

@media (max-width:850px){
  .quote__img {
    width: 45%;
    left: 10px;
  }
}

@media (max-width:765px){
  .quote {
    margin: 100px 0 100px;
  }

  .quote h1 {
    font-size: 22px;
    line-height: 1.5rem;
    margin-bottom: 50px;
  }
  
  .quote__img {
    width: 50%;
    left: 0;
  }
  
  .quote__container {
    width: 50%;
    padding: 100px 0;
  }

  .quote__button {
    padding: 15px;
    font-size: 20px;
  }
}

@media (max-width:500px){
  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  }

  .quote h1 {
    font-size: 20px;
    line-height: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .quote__img {
    width: 40%;
    left: 0;
  }
  
  .quote__container {
    width: 90%;
    padding: 50px 0 150px;
    margin: 0 auto 0;
  }

  .quote__content {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .quote__button {
    padding: 15px;
    font-size: 20px;
    margin: 0 auto 60px;
  }
}