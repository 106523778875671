.process {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.process h1 {
  font-size: 48px;
  margin-bottom: 20px;
  padding: 0;
}

.process__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 50px 0 0;
}

.process__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.process__card__img {
  margin-bottom: 10px;
}

.process__card__img2 {
  width: 80px;
  margin-top: 60px;
}

.process__card__text {
  width: 200px;
  font-weight: 400;
  text-align: center;
}

@media (max-width:1099px){
  .process__card__img {
    width: 120px;
  }

  .process__card__img2 {
    width: 60px;
    margin-top: 50px;
  }
}

@media (max-width:996px){
  .process__card__img {
    width: 120px;
  }

  .process__card__img2 {
    width: 50px;
    margin-top: 45px;
  }

  .process__card__text {
    width: 150px;
  }
}

@media (max-width:766px){
  .process h1 {
    font-size: 36px;
    margin-bottom: 0;
  }
  
  .process__container {
    flex-direction: column;
    align-items: center;
    margin: 30px 0 0;
  }

  .process__card__img {
    width: 120px;
    margin-bottom: 10px;
  }
  
  .process__card__img2 {
    width: 80px;
    margin: 50px auto;
    rotate: 90deg;
  }
  
  .process__card__text {
    width: 100%;
    font-weight: 400;
    text-align: center;
  }
}

@media (max-width:500px){
  .process h1 {
    font-size: 32px;
  }

  .process__card__img {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .process__card__img2 {
    width: 80px;
    margin: 50px auto;
    rotate: 90deg;
  }
  
  .process__card__text {
    width: 100%;
    font-weight: 400;
    text-align: center;
  }
}
