.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.about h1 {
  width: 100%;
  font-size: 48px;
  margin-bottom: 10px;
  text-align: center;
}

.about p {
  margin-bottom: 100px;
  text-align: center;
}

@media (max-width:765px){
  .about {
    width: 90%;
  }
  
  .about h1 {
    font-size: 32px;
    margin-bottom: 5px;
  }
  
  .about p {
    margin-bottom: 50px;
  }
}