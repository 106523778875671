.popup {
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.popup__container {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  padding: 40px 50px;
  z-index: 25;
}

.popup__close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
  align-self: flex-end;
  cursor: pointer;
}

.popup__title {
  font-size: xx-large;
  text-align: center;
  color: black;
  margin-bottom: 40px;
}

.popup__form-input {
  background-color: rgb(235, 235, 235);
  border: none;
  border-bottom: 2px solid green;
  font-size: 18px;
  margin: 0 0 25px;
  padding: 10px;
}

.popup__form-input:focus {
  outline: none;
}

.popup__form-input-text {
  height: 150px;
  background-color: rgb(235, 235, 235);
  border: none;
  font-size: 18px;
  line-height: 1.5rem;
  margin: 0;
  padding: 10px;
  resize: none;
  outline: none;
}

.popup__form-button {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  font-size: 20px;
  color: white;
  margin: 40px auto 0;
  padding: 7px 30px 10px;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.popup__form-button:hover {
  background: rgb(0, 97, 0);
}

.popup__form-button-loading {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  font-size: 20px;
  color: black;
  margin: 40px auto 0;
  padding: 7px 30px 10px;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

@media (max-width:765px){
  .popup {
    top: 67px;
  }
  
  .popup__container {
    width: 60%;
    padding: 20px 40px;
  }
}

@media (max-width:500px){
  .popup {
    top: 60px;
  }
  
  .popup__container {
    width: 80%;
    padding: 10px 20px;
  }

  .popup__close {
    right: 10px;
    top: 10px;
    width: 20px;
  }
  
  .popup__title {
    font-size: 26px;
    margin: 20px;
  }

  .popup__form-input {
    font-size: 16px;
    margin: 0 0 15px;
    padding: 8px;
  }

  .popup__form-input-text {
    height: 120px;
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
  }

  .popup__form-button {
    width: 50%;
    font-size: 18px;
    margin: 20px auto 10px;
    padding: 9px 30px 10px;
  }
}