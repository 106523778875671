.navbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px 12px;
}

.sticky {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.menu {
  display: flex;
}

.inner-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}

.inner-nav li{
  margin-left: 30px;
}

.logo-container{
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
}

.logo {
  width: 50px;
  margin-right: 10px;
}

.logo-container h1{
  font-size: 28px;
}

.button a {
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  white-space: nowrap;
  font-size: 14pt;
  font-weight: bold;
  background: rgb(0, 151, 0);
  transition: ease-in-out 0.2s;
}

.button a:hover {
  background: rgb(0, 138, 0);
}

.hamburger{
  width: 25px;
  height: 2px;
  background-color: rgb(40,40,40);
  position: relative;
  display: none;
}

.hamburger::before{
  content: "";
  position: absolute;
  top: 8px;
  width: 25px;
  height: 2px;
  background-color: rgb(40,40,40);
}

.hamburger::after{
  content: "";
  position: absolute;
  bottom: 8px;
  width: 25px;
  height: 2px;
  background-color: rgb(40,40,40);
}

.hamburger1{
  width: 25px;
  height: 2px;
  background-color: rgb(40,40,40);
  position: relative;
  display: none;
}

.hamburger1::before{
  content: "";
  position: absolute;
  transform: rotate(45deg);
  width: 25px;
  height: 2px;
  background-color: rgb(40,40,40);
  transition: ease-in 0.3s;
}

.hamburger1::after{
  content: "";
  position: absolute;
  transform: rotate(-45deg);
  width: 25px;
  height: 2px;
  background-color: rgb(40,40,40);
  transition: ease-in 0.3s;
}

@media (max-width:833px){
  .navbar{
    padding: 10px 10px 10px 20px;
  }

  .logo {
    width: 45px;
    margin-right: 5px;
  }

  .logo-container h1{
    font-size: 24px;
  }

  .inner-nav li{
    margin-left: 20px;
  }
}

@media (max-width:765px){
  .navbar{
    padding: 10px 10px 10px 20px;
  }

  .logo {
    width: 45px;
    margin-right: 5px;
  }

  .logo-container h1{
    font-size: 24px;
  }

  .hamburger{display: block;}
  .hamburger1{display: block; background-color: transparent;}

  .inner-nav{
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background: #54a345;
    margin-top: 42px;
    padding: 30px 0;
    border-radius: 0 0 5px 5px;
    transition: ease-in 0.3s;
    text-align: center;
  }

  .inner-nav li {
    width: 100%;
    font-size: 16px;
    margin: 0 auto 25px;
  }

  .inner-nav li a{
    width: 100%;
    padding: 15px 50px;
    color: white;
  }
}

@media (max-width:500px){
  .logo {
    width: 40px;
  }

  .logo-container h1{
    font-size: 20px;
  }

  .inner-nav{
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background: #54a345;
    margin-top: 39px;
    padding: 20px 0 0;
  }

  .inner-nav li {
    width: 100%;
    font-size: 16px;
    margin: 0 auto 25px;
  }

  .inner-nav li a{
    width: 100%;
    padding: 15px 50px;
    color: white;
  }
}