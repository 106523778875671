.hero {
  position: relative;
  margin-bottom: 100px;
}

.hero__background {
  width: 100%;
}

.hero-container {
  position: absolute;
  top: 0;
  bottom: 5px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
}

.hero__content {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 60px;
}

.hero__content h1 {
  font-size: 48px;
  color: white;
}

.hero__content p {
  font-size: 20px;
  color: white;
  margin: 20px 0 35px;
}

.hero__buttons-container {
  display: flex;
  gap: 20px;
}

.hero__button1 a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  margin: 0;
  padding: 7px 30px 10px;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.hero__button1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  font-size: 20px;
  color: white;
  margin: 0;
  padding: 7px 30px 10px;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.hero__button1:hover {
  background: rgb(0, 97, 0);
}

.hero__button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  border: 1px solid white;
  background: transparent;
  margin: 0;
  padding: 7px 30px 10px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.hero__button2:hover {
  color: rgb(194, 194, 194);
  border: 1px solid rgb(194, 194, 194);
}

@media (max-width:765px){
  .hero__content {
    width: 85%;
    padding: 20px;
  }
  
  .hero__content h1 {
    font-size: 32px;
  }
  
  .hero__content p {
    font-size: 18px;
    margin: 20px 0 30px;
  }
  
  .hero__buttons-container {
    gap: 10px;
  }
  
  .hero__button1 {
    font-size: 18px;
    padding: 5px 20px;
  }

  .hero__button2 {
    font-size: 18px;
    padding: 5px 20px;
  }
}

@media (max-width:500px){
  .hero {
    margin-bottom: 50px;
  }

  .hero__content {
    width: 90%;
    padding: 10px 15px;
    margin: 0 auto 0;
  }
  
  .hero__content h1 {
    font-size: 30px;
  }
  
  .hero__content p {
    font-size: 16px;
    margin: 10px 0 20px;
  }
  
  .hero__buttons-container {
    gap: 10px;
  }
  
  .hero__button1 {
    font-size: 16px;
    padding: 6px 22px;
  }

  .hero__button2 {
    font-size: 16px;
    padding: 5px 20px;
  }
}