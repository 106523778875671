.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 100px 150px;
  overflow: hidden;
}

.footer__img {
   width: 200%;
   position: absolute;
   top: 0;
   filter: blur(3px);
   -webkit-filter: blur(3px);
   z-index: -1;
}

.footer__title {
  font-size: 48px;
  margin-bottom: 30px;
  padding: 0;
}

.footer__text {
  font-size: 24px;
  font-weight: 500;
}

.footer__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  border-radius: 10px;
  margin-top: 50px;
}

.footer__list-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 100px;
}

.footer__list-container1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.footer__list-title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
}

.footer__list {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  list-style: none;
  margin-top: 20px;
}

.footer__list-item {
  text-decoration: none;
  border-radius: 40px;
}

.footer__list-image {
  display: flex;
  width: 50px;
  margin: 0;
  padding: 0;
  transition: all 0.2s;
}

.footer__list-image:hover {
  margin-bottom: 5px;
}

.footer__list-button {
  border-radius: 20px;
  margin: 20px 0 0;
  padding: 5px 40px;
  transition: all 0.2s;
}

.footer__list-button:hover {
  cursor: pointer;
  padding: 7px 45px;
  border-color: rgba(0, 0, 0, 0.5);
}

.footer__form {
  width: 35%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 25px;
}

.footer__form-title {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}

.footer__form-label {
  font-size: 18px;
  margin-bottom: 5px;
  padding: 0;
}

.footer__form-input {
  background-color: rgb(235, 235, 235);
  border: none;
  font-size: 18px;
  margin: 0 0 20px;
  padding: 10px;
}

.footer__form-input-text {
  height: 100px;
  background-color: rgb(235, 235, 235);
  border: none;
  font-size: 18px;
  line-height: 1.5rem;
  margin: 0;
  padding: 10px;
  resize: none;
}

.footer__form-success {
  text-align: center;
  margin: auto;
  padding: 0;
}

.footer__form-image {
  width: 50%;
  margin: auto;
  padding: 0;
}

.footer__form-error {
  margin: 0;
  padding: 10px 0 0;
  color: red;
}

.footer__form-button {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  font-size: 20px;
  color: white;
  margin: 35px auto 10px;
  padding: 7px 30px 10px;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.footer__form-button-loading {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  font-size: 20px;
  color: black;
  margin: 35px auto 10px;
  padding: 7px 30px 10px;
  border: 1px solid black;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.footer__form-button:hover {
  background: rgb(0, 97, 0);
}

.footer__rights {
  position: absolute;
  font-size: 18px;
  bottom: 0;
  left: 0;
  margin: 20px;
  transition: all 0.3s;
}

.footer__rights:hover {
  color: grey;
}

@media screen and (max-width:900px) {
  .footer {
    padding: 50px 50px 150px;
  }

  .footer__list-container {
    width: 50%;
    align-items: flex-start;
    gap: 50px;
  }

  .footer__list-container1 {
    width: 100%;
  }

  .footer__form {
    width: 40%;
  }
}

@media (max-width:765px) {
  .footer__container {
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px;
  }

  .footer__title {
    font-size: 32px;
    margin-bottom: 0;
    padding: 0;
  }
  
  .footer__text {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer__list-container {
    width: 80%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;
  }

  .footer__list-button {
    padding: 10px;
  }

  .footer__list-image {
    width: 40px;
  }

  .footer__form {
    width: 80%;
    margin-top: 20px;
  }
}

@media (max-width:500px) {
  .footer__container {
    width: 100%;
    margin: 20px 0 0;
    padding: 0;
  }

  .footer__img {
    width: 800%;
 }

  .footer__title {
    margin: 0;
    padding: 0;
  }

  .footer__container {
    width: 100%;
  }

  .footer__list-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .footer__list {
    width: 100%;
    height: 0;
    margin-top: 50px;
  }

  .footer__form {
    width: 100%;
    margin-top: 20px;
  }

  .footer__rights {
    font-size: 14px;
    right: 0;
    text-align: center;
    margin: 0 auto 20px;
  }
}