* {
  list-style: none;
  text-decoration: none;
  color: #323232;
  font-size: calc(8px + 1vmin);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

@media (max-width: 900px) {
  * {
    font-size: calc(12px + 1vmin);
  }
}