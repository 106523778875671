.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  user-select: none;
}

.services h1 {
  font-size: 48px;
  margin-bottom: 30px;
  padding: 0;
}

.services__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 40px;
  user-select: none;
}

.service__container_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.service__container_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.services__card {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border: none;
  border-left: 3.5px white solid;
  background: transparent;
  cursor: pointer;
}

.services__card_active {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-left: 3.5px white solid;
  cursor: pointer;
  border: none;
  border-left: 3.5px green solid;
  background: transparent;
  box-shadow: 5px 10px 5px 10px rgba(0,0,0,0.05);
  -webkit-box-shadow: 5px 10px 10px 1px rgba(0,0,0,0.05);
  -moz-box-shadow: 5px 10px 10px 1px rgba(0,0,0,0.05);
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.text__active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.textItem__text {
  line-height: 1.7;
  margin-bottom: 50px;
}

.serviceItem__image {
  width: 50%;
}

.collapse2 {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.3s ease;
}

.collapse2 .show {
  height: auto;
}

@media (min-width:766px){
  .collapse3 {
    display: none;
  }
}

@media (max-width:765px){
  .services {
    margin-bottom: 200px;
  }

  .services h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .services__container {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 0;
    margin: 0;
  }

  .service__container_right {
    display: none;
  }

  .collapse3 {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: 0.3s ease;
  }
  
  .collapse3 .show {
    height: auto;
  }

  .services__card {
    width: 90%;
    gap: 10px;
    padding: 10px;
    border-left: 3.5px white solid;
    cursor: pointer;
  }

  .services__card_active {
    width: 90%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
  }

  .textItem__text {
    line-height: 1.7;
    margin-bottom: 0;
    padding: 0;
  }

  .serviceItem__image {
    display: none;
  }

  img {
    width: 10%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width:500px){
  .services {
    margin-bottom: 50px;
  }

  img {
    width: 15%;
  }
}